<template>
  <div style="background-color: #fff" class="education-index">
    <carouselBanner paddingTop="13.5%" :bannerType="9" />
    <div class="e-commerce-information" v-loading="information_loading">
      <div class="information-title">
        <div>法律咨询资讯</div>
        <div
          @click="viewMore('information')"
          v-if="!information_loading && information_data.length !== 0"
        >
          查看更多<i class="el-icon-d-arrow-right"></i>
        </div>
      </div>
      <div class="information-value">
        <div
          v-for="val in information_data"
          :key="val.id"
          class="information-item"
        >
          <information :item_data="val" @viewDetail="onViewDetail" />
        </div>
        <noDataImg
          v-if="!information_loading && information_data.length === 0"
        ></noDataImg>
      </div>
    </div>
    <div class="e-commerce-video">
      <div class="video-title">
        <div>法律咨询视频</div>
        <div
          @click="viewMore('video')"
          v-if="!video_loading && video_list.length !== 0"
        >
          查看更多<i class="el-icon-d-arrow-right"></i>
        </div>
      </div>
      <div class="video-value">
        <div v-for="val in video_list" :key="val.id" class="video-item">
          <e-commerce-video :item_data="val" />
        </div>
        <noDataImg v-if="!video_loading && video_list.length === 0"></noDataImg>
      </div>
    </div>
  </div>
</template>
<script>
import carouselBanner from "@/baseComponents/carouselBanner";
import information from "~xif/components/crossBorder/information";
import eCommerceVideo from "~xif/components/crossBorder/eCommerceVideo";

export default {
  components: {
    carouselBanner,
    information,
    eCommerceVideo,
  },
  metaInfo: {
    title: "厦门货代协会--跨境电商",
  },
  data() {
    return {
      video_list: [],
      video_loading: false,
      information_data: [], //资讯数据
      information_loading: false,
    };
  },
  mounted() {
    this.getInformationList();
    this.getVideoList();
  },
  methods: {
    // 获取视频列表
    async getVideoList() {
      try {
        this.video_loading = true;
        let params = {
          source: this.PJSource,
          start: 0,
          limit: 8,
          video_category: 6
        };
        let list = await this.$store.dispatch(
          "API_company/association_video_List",
          params
        );
        this.video_list = list.data;
        this.video_loading = false;
      } catch (err) {
        this.video_loading = false;
      }
    },
    // 获取资讯列表
    async getInformationList() {
      try {
        this.information_loading = true;
        let params = {
          content_type: 20,
          source: this.PJSource,
          nopage: 0,
          limit: 4,
          start: 0,
          is_show: 1,
          language: this.LOCALE === "en" ? 1 : 2,
        };
        let res = await this.$store.dispatch(
          "API_index/getStaticContent",
          params
        );
        if (res.success) {
          this.information_data = res.data;
        }
        this.information_loading = false;
      } catch (err) {
        this.information_loading = false;
      }
    },
    // 查看更多
    viewMore(view_type) {
      let path = "/lawInfoList";
      let type = 1; // 资讯--1；视频--2；
      if (view_type === "information") {
        type = 1;
      } else {
        type = 2;
      }
      this.$router.push({
        path,
        query: {
          parameter: this._encode({
            type,
          }),
        },
      });
    },
    // 查看详情
    onViewDetail(value) {
      this.$router.push({
        path: "/detail",
        query: { id: value.id },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.education-index {
  background: #f5f5f5;
  overflow: hidden;
  .e-commerce-information,
  .e-commerce-video {
    width: 1200px;
    margin: 0 auto;
  }
  .e-commerce-information {
    margin-top: 60px;
    margin-bottom: 36px;
  }
  .information-title,
  .video-title {
    display: flex;
    justify-content: space-between;
    > div:nth-child(1) {
      font-size: 24px;
      font-weight: bold;
      color: #0d6bb9;
    }
    > div:nth-child(2) {
      font-size: 14px;
      color: #999999;
      vertical-align: bottom;
      line-height: 31px;
    }
    > div:nth-child(2):hover {
      color: #0d6bb9;
      cursor: pointer;
    }
  }
  .information-value,
  .video-value {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    position: relative;
    min-height: 240px;
    .information-item,
    .video-item {
      margin-bottom: 24px;
      margin-right: 24px;
    }
    .information-item:nth-child(2n) {
      margin-right: 0;
    }
    .video-item:nth-child(4n) {
      margin-right: 0;
    }
  }
  .e-commerce-video {
    margin-bottom: 76px;
  }
}
</style>